import request from '../utils/request';
import qs from "qs";

//鉴权获取跳转地址
export const getPoolURL = param => {
	return request.post('/api/phoneNumberPool/getURL', qs.stringify(param))
};
//查询当前企业下的用量
export const queryEnterpriseDosage = param => {
	return request.post('/api/phoneNumberPool/query', qs.stringify(param))
};
//查询当前企业员工
export const queryUser = param => {
	return request.post('/api/phoneNumberPool/queryUser', qs.stringify(param))
};
//打通账号
export const openAccount = param => {
	return request.post('/api/phoneNumberPool/openAccount', qs.stringify(param))
};
//查询企业下已开通的账号
export const queryAccount = param => {
	return request.post('/api/phoneNumberPool/queryAccount', qs.stringify(param))
};
//新建线索池
export const addCreatePool = param => {
	return request.post('/api/phoneNumberPool/createPool', qs.stringify(param))
};
//领取
export const getTrial = param => {
	return request.post('/api/phoneNumberPool/trial', qs.stringify(param))
};
//赠送
export const getBatch = param => {
	return request.post('/api/phoneNumberPool/batch', param, { headers: { 'Content-Type': 'application/json' } })
};
//分配
export const collectData = param => {
	return request.post('/api/phoneNumberPool/collectData',
		param, { headers: { 'Content-Type': 'application/json' } })
};
// 导出回收客户
export const exportData = param => {
	return request.post('/api/phoneNumberPool/exportData', qs.stringify(param), {
		responseType: 'blob'
	})
};

//购买线索--支付
export const phoneNumberPoolPay = param => {
	return request.post('/api/phoneNumberPool/pay', qs.stringify(param))
};
//线索--通话记录
export const phoneNumberPoolhistory = param => {
	return request.post('/api/phoneNumberPool/history', qs.stringify(param))
};
//线索--删除导航
export const deletePhoneNumberPool = param => {
	return request.post('/api/phoneNumberPool/delete', qs.stringify(param))
};
//线索--删除数据
export const deleteDataPhoneNumberPool = param => {
	return request.post('/api/phoneNumberPool/deleteData', qs.stringify(param))
};
//线索--转移数据
export const transferPhoneNumberPool = param => {
	return request.post('/api/phoneNumberPool/transfer', qs.stringify(param))
};
//线索--可分配员工
export const findNormal = param => {
	return request.post('/api/phoneNumberPool/findNormal', qs.stringify(param))
};
//线索--导出数据
export const exportDataPhoneNumberPool = param => {
	return request.post('/api/phoneNumberPool/exportData', qs.stringify(param), {
		responseType: 'blob'
	})
};

export const deleteIsEmpty = id => {
	return request.get('/api/phoneNumberPool/deleteIsEmpty?id=' + id)
};