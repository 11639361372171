<template>
	<div><iframe id="iframe" :src="iframeURL" style="height: 800px; width: 100%;" frameborder="0"></iframe></div>
</template>

<script>
	import { getPoolURL } from '../api/powerandhealth.js';
	export default {
		name: 'mapSearch',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				iframeURL: ''
			};
		},
		created() {
			this.getData();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getData() {
				let data = {
					userId: localStorage.getItem('user'),
					type: 'searchCustomer/map-search'
				};
				getPoolURL(data).then(res => {
					if (res.code == 200) {
						this.iframeURL = res.data;
					} else {
						if (res.message == '该账号还未开通') {
							this.$message.error(res.message);
							setTimeout(() => {
								this.$router.push('/guide');
							}, 1000);
						} else {
							this.$message.error(res.message);
						}
					}
				});
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover{background: #66b1ff} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}
</style>